import React from "react"
import styled from "styled-components"

import Img from "gatsby-image"

const BlogHeader = (props) => {
  const { markdownRemark } = props.data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  const featuredImg = frontmatter.image.childImageSharp.fluid

  return (
    <BlogHeaderContainer>
      <BlogHeaderImg fluid={featuredImg} />
      <BlogHeaderOverlay>
        <h1>{frontmatter.title}</h1>
        {!props.hideDate && <p>Posted on {frontmatter.date}</p> }
      </BlogHeaderOverlay>
    </BlogHeaderContainer>
  )
}

const BlogHeaderImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`

const BlogHeaderContainer = styled.div`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 40px;
`

const BlogHeaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  
  background: rgba(0, 0, 0, 0.6);

  h1 {
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin: 0;
  }
`

export default BlogHeader;