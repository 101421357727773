import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Section, Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import GetStarted from '../components/sections/get-started'
import Footer from "../components/sections/footer"
import BlogHeader from "../components/blog-header"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title} 
        description={frontmatter.description}
        image={frontmatter.image.childImageSharp.fluid.src}
      />

      <Navigation />

      <StyledSection>
        <StyledContainer className="blog-post">

          <BlogHeader data={data} hideDate={true} />

          <BlogText
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </StyledContainer>

        <GetStarted/>
      </StyledSection>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const BlogText = styled.div`
  padding: 0 20px;
  padding-bottom: 80px;

  a {
    color: #fff;
  }
`

const StyledSection = styled(Section)`
  background-color: #212d3b;
  padding-bottom: 0;

  h1, h2, h3, p, li {
    color: #fff;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
  }

  ol {
    list-style-position: inside;
  }

  ul, ol {
    padding-left: 5px;

    li {
      padding: 15px 0;
      border-top: 1px solid #777;

      &:last-child {
        border-bottom: 1px solid #777;
      }
    }
  }
`

const StyledContainer = styled(Container)`
  @media(min-width: 1199px) {
    max-width: 800px;
  }

  @media(min-width: 991px) {
    max-width: 800px;
  }
`;